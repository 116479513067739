<template>
  <b-card-body :class="{'loading': loading}">
    <money-by-routes-report-table
      :data="data"
      :total="total"
      :period="filter.period"
    />
  </b-card-body>
</template>

<script>
import {customersRoutesInAuctionsStatistics} from '@/services/api';
import MoneyByRoutesReportTable from './MoneyByRoutesReportTable';

export default {
  name: 'MoneyByRoutesReport',
  components: {MoneyByRoutesReportTable},
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal) {
        this.customersDateAuctionStatisticsMoneyByRoutesChartsData(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.customersDateAuctionStatisticsMoneyByRoutesChartsData(this.filter);
  },
  methods: {
    async customersDateAuctionStatisticsMoneyByRoutesChartsData(data) {
      this.loading = true;
      const response = await customersRoutesInAuctionsStatistics(this.$store.state.user.ownerId, data);
      if (response && response.status === 200) {
        this.data = response.data.page;
        this.total = response.data.total;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
